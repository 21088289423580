import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import BucketsTable from 'components/buckets/BucketsTable';
import { RootState } from 'services/store';
import { checkIsAdmin, checkIsChronoTech } from 'utils/permissions';
import { loadBucket, fetchBuckets } from 'services/buckets/operations';
import { fetchDrugs } from 'services/drugs/operations';
import { LoadingButton } from 'components/shared/LoadingButton';
import { HttpResponseError } from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefillBucketsModal from 'components/buckets/RefillModals/RefillBucketsModal';
import ImportBucketListModal from 'components/buckets/ImportBucketListModal';
import { fetchOrganizations } from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';
import './style.scss';
import LoadingErrorModal from 'components/buckets/LoadingErrorModal';

const BucketsPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const buckets = useSelector((state: RootState) => state.buckets);
  const drugs = useSelector((state: RootState) => state.drugs);
  const organizations = useSelector((state: RootState) => state.organizations);
  const LOAD_BUCKET_DELAY = 3000;
  const loginState = useSelector((state: RootState) => state.login);
  const isAdmin = checkIsAdmin(loginState);
  const isChronoTech = checkIsChronoTech(loginState);
  const [bucketLoading, setBucketLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [toRefillIds, setToRefillIds] = useState<string[]>([]);
  const [openRefillModal, setOpenRefillModal] = useState<boolean>(false);
  const [importBucketList, setImportBucketList] = useState<boolean>(false);
  const [organizationView, setOrganizationView] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (buckets) {
      setToRefillIds(buckets.filter((b) => b.toRefill).map((b) => b.id));
    }
  }, [buckets, setToRefillIds]);

  const handleLoadBucket = async () => {
    try {
      setBucketLoading(true);
      await loadBucket()(dispatch);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        setLoadingError(content?.error?.message || t('errorOccurred'));
        return;
      }
      console.error('ERROR LOADING BUCKET', err);
    } finally {
      setTimeout(() => {
        setBucketLoading(false);
      }, LOAD_BUCKET_DELAY);
    }
  };

  const getPage = () => (
    <>
      <h2>{t('buckets')}</h2>
      <div
        className="buckets-btns"
        style={{
          float: 'right',
          marginBottom: 20,
          cursor: 'pointer',
        }}
      >
        {(isAdmin || isChronoTech) && (
          <select
            className="organization-view"
            name="organizationName"
            onChange={(e) => setOrganizationView(e.target.value)}
          >
            <option value="">Toutes</option>
            {organizations.map((o) => (
              <option value={o.id}>{o.name}</option>
            ))}
          </select>
        )}
        {(isAdmin || isChronoTech) && (
          <Button
            variant="outline-secondary"
            style={{ marginRight: 15 }}
            onClick={() => setImportBucketList(true)}
          >
            <FontAwesomeIcon icon="file-import" />
          </Button>
        )}
        <Button
          onClick={() => setOpenRefillModal(true)}
          variant="secondary"
          style={{ marginRight: 15 }}
        >
          <FontAwesomeIcon icon="list" style={{ marginRight: 15 }} />
          <span>{t('toRefill')}</span>
          <span> ({toRefillIds.length})</span>
        </Button>
        <LoadingButton
          variant="success"
          onClick={() => handleLoadBucket()}
          loading={bucketLoading}
          disabled={bucketLoading}
        >
          {t('load')}
        </LoadingButton>
      </div>
      <BucketsTable organizationView={organizationView} />
      <ImportBucketListModal show={importBucketList} close={() => setImportBucketList(false)} />
      <LoadingErrorModal error={loadingError} handleClose={() => setLoadingError(null)} />
      <RefillBucketsModal
        isOpen={openRefillModal}
        bucketsId={toRefillIds}
        editList={setToRefillIds}
        onClose={() => setOpenRefillModal(false)}
      />
    </>
  );

  return (
    <ResourcesHandler
      resources={[buckets, drugs, organizations]}
      resourceFetchers={[
        () => dispatch(fetchBuckets()),
        () => dispatch(fetchDrugs()),
        () => dispatch(fetchOrganizations()),
        () => dispatch(fetchUsers()),
      ]}
      getContents={getPage}
    />
  );
};

export default BucketsPage;
